<template>
  <v-card
    v-resizable="onResize"
    resizable-top
    class="d-flex flex-column fill-height no-user-select resizable-wrapper"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-data-table
      :height="height"
      :headers="headers"
      :items="items"
      dense
      hide-default-header
      hide-default-footer
      fixed-header
      class="cursor-pointer"
      @click:row="(item) => $emit('item-click', item.id)"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon
          :color="'#' + (item.color ? item.color : getCategory(item.categoryId).color)"
        >
          {{ 'mdi-' + (item.icon ? item.icon : getCategory(item.categoryId).icon) }}
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
    },

    data: function () {
      return {
        height: 200,
        headers: [
          {
            value: 'icon',
            align: 'center',
            cellClass: 'icon-col',
          },
          {
            value: 'name',
            sortable: true,
          },
          {
            value: 'actions',
            align: 'center',
            cellClass: 'icon-col',
          },
        ],
      };
    },

    methods: {
      onResize (payload) {
        this.height = payload.height - 5;
        this.$emit('resize', payload.resizing);
      },

      getCategory (categoryId) {
        return this.categories.find((category) => category.id === categoryId);
      },
    },
  };
</script>

<style scoped lang="sass">
  .resizable-wrapper
    border-top: 5px solid var(--v-primary-base)
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
    width: 100%
</style>
